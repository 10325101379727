@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

:root {
  --background: rgba(240, 240, 240, 0.9); /* Light pastel background */--text-color: #2d2d2d; /* Darker text color for contrast */--highlight-color: #a2d2ff; /* Soft pastel blue */--green-color: #b5e48c; /* Soft pastel green */--card-bg: rgba(255, 255, 255, 0.85); /* Slightly more opaque card background */--frosted-effect: blur(8px); /* Reduced blur for a more solid look */
  --text-color: #2d2d2d;
  --highlight-color: #007acc;
  --green-color: #28a745;
  --card-bg: rgba(255, 255, 255, 0.4);
  --frosted-effect: blur(1.5px);
}

body {
  background-color: var(--background);
  color: var(--text-color);
  font-family: 'Share Tech Mono', monospace;
  background: linear-gradient(135deg, #f3eac2 0%, #a2d2ff 100%);
  backdrop-filter: var(--frosted-effect);
}

.monospaced-font {
  font-family: 'Share Tech Mono', monospace;
}

.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.content-wrapper {
  flex: 1;
  padding-top: 0px; /* Padding to account for the top bar height */
  display: flex;
  flex-direction: column;
  height: 80vh; /* Ensure it fills the remaining space */
  overflow-y: auto;
}

.top-bar {
  z-index: 100;
  background-color: var(--background);
  backdrop-filter: var(--frosted-effect);
  border-bottom: 1px solid var(--highlight-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Fixed height */
  transition: top 0.3s ease-in-out, background-color 0.3s ease;
}

.hidden-top-bar {
  top: -60px;
}

.terminal-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 60px; /* Adjust this based on your top bar's height */
  padding: 20px;
  height: calc(100vh - 60px); /* Adjust based on your top bar's height */
  overflow-y: auto; /* Allow scrolling for the terminal content */
  backdrop-filter: var(--frosted-effect);
}

.react-terminal-ui {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Ensure the input stays at the bottom */
  overflow-y: hidden; /* Prevent any unnecessary scrolling within the terminal */
}

.terminal {
  font-size: 0.9rem;
  flex-grow: 1;
  margin: 0;
}

.detailed-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  backdrop-filter: var(--frosted-effect);
}

.padded-card {
  padding: 20px;
  margin-bottom: 20px;
  background-color: var(--card-bg);
  background-blend-mode: multiply;
  border-radius: 8px;
  backdrop-filter: var(--frosted-effect);
  color: var(--text-color);
  box-shadow: 04px8pxrgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.tab-content-wrapper {
  flex: 1;
  overflow-y: auto;
  transition: opacity 0.3s ease;
}

.tab-content-wrapper.fade-in {
  opacity: 1;
}

.tab-content-wrapper.fade-out {
  opacity: 0;
}

button {
  background-color: var(--background);
  color: var(--text-color);
  border: 1px solid var(--highlight-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  backdrop-filter: var(--frosted-effect);
}

button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.vertical-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 60px;
  width: 100%;
  backdrop-filter: var(--frosted-effect);
  z-index: 40;
  background-color: var(--background);
}

@media (max-width: 768px) {
  .top-bar {
    padding: 10px;
  }

  .tab-content-wrapper {
    padding-top: 10px;
  }

  .terminal-wrapper,
  .detailed-view {
    margin-top: 80px;
  }

  .react-terminal-ui {
    white-space: normal !important;
    word-wrap: break-word !important;
  }

  h1 {
    margin-top: 0;
  }

  .vertical-menu {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 40;
  }
}